import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import CardRelated from '../components/cardRelated'
import CarouselQuote from '../components/carouselQuote'
import AltSection from '../components/columnAlternating'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

const MarketsTemplate = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol lg={post.frontmatter.headline.length >= 1 ? '8' : '12'}>
              <div className="font-w-400 text-xs-extra-large divlink text-opening-para"> {post.frontmatter.openingpara} </div>
              <div className="richtext divlink mt-3" dangerouslySetInnerHTML={{ __html: post.html }} />
            </MDBCol>

            {post.frontmatter.headline && post.frontmatter.headline.length > 0 && (
              <MDBCol lg="4" md="12" className="pl-lg-5">
                {post.frontmatter.headline.map((headlines, index) => (
                  <div className="headline-tile mt-lg-3 item-headline" key={index} >
                    <div className="headline-icon"> <FontAwesomeIcon icon={['fat', headlines.icon]} size="2x" />
                    </div>
                    <div className="headline-content">
                      <h3 className="font-alt font-w-700 letter-spacing-1 text-xs-medium text-medium"> {headlines.title} </h3>
                      <p className="font-alt letter-spacing-1 text-xs-medium text-small"> {headlines.description} </p>
                      <div className="headline-action font-alt letter-spacing-1 text-xs-medium text-small">
                        <Link to={headlines.link} className="effect-noline">
                          {headlines.linktext}
                            <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </MDBCol>
            )}
          </MDBRow>
        </MDBContainer>
      </section>

      {post.frontmatter.section && post.frontmatter.section.length > 0 && (
        <section className="bg-white-grey-gradient">
          <MDBContainer>
            {post.frontmatter.section.map((sections, index) => {
              return (
                <div key={index}>
                  <AltSection
                    key={index}
                    title={sections.title}
                    subtitle={sections.subtitle}
                    description={sections.description}
                    image={sections.image.childImageSharp.gatsbyImageData}
                    imageAltText={sections.alttext}
                    placement={sections.placement}
                  />
                </div>
              )
            })}
          </MDBContainer>
        </section>
      )}

      {post.frontmatter.related && post.frontmatter.related.length > 999 && (
        <section className="bg-gray-light">
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12" className="mb-5">
                <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium"> Related items </h3>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              {post.frontmatter.related.map((relate, index) => {
                return (
                  <CardRelated
                    key={index}
                    collg="4"
                    colmd="6"
                    title={relate.title}
                    image={relate.image.childImageSharp.gatsbyImageData}
                    description={relate.description}
                    linktext={relate.linktext}
                    link={relate.link}
                  />
                )
              })}
            </MDBRow>
          </MDBContainer>
        </section>
      )}

      {post.frontmatter.quote && post.frontmatter.quote.length > 0 && (
        <section className="blockback">
          {post.frontmatter.quote.map((quotes, index) => {
            return (
              <CarouselQuote key={index} quotenumber={quotes.quotenumber} strapline={quotes.strapline} author={quotes.author} />
            )
          })}
        </section>
      )}
    </Layout>
  )
}
export default MarketsTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {     
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "markets" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description 
        }
        template
        title
        subtitle
        openingpara
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        ctatext
        ctaslug         
        headline {
          title
          icon
          description
          linktext
          link
        }
        quote {
          quotenumber
          strapline
          author
        }
        section {
          title
          subtitle
          image {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
          alttext
          placement
          description
        }
        related {
          title
          image {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
          description
          linktext
          link
        }
      }
    }
  }
`